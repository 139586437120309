<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            :src="empresaLogo"
            alt="logo"
          />
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('recuperarSenha.titulo') }} 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('recuperarSenha.text') }}
        </b-card-text>

        <b-overlay
          :show="loadingOverlay"
          rounded="sm"
        >
          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                variant="primary"
                block
                type="submit"
              >
                {{ $t('recuperarSenha.enviarBtn') }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-overlay>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> {{ $t('recuperarSenha.linkText') }}
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BImg, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BOverlay,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import configuracaoContaStoreModule from './conta/configuracao/configuracaoContaStoreModule'

const CONFIGURACAO_CONTA_STORE_MODULE_NAME = 'configuracao-conta'

export default {
  components: {
    BCard,
    BLink,
    BImg,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
  },
  data() {
    return {
      loadingOverlay: false,
      userEmail: '',
      // validation
      required,
      email,
    }
  },

  created() {
    if (!store.hasModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)) store.registerModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME, configuracaoContaStoreModule)
  },

  beforeDestroy() {
    if (store.hasModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)) store.unregisterModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)
  },

  setup() {
    const { empresa, empresaLogo } = useAppConfig()
    const { appName } = $themeConfig.app

    return {
      empresa,
      empresaLogo,
      appName,
    }
  },

  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loadingOverlay = true
          this.$store.dispatch('configuracao-conta/recuperarSenha', { email: this.userEmail, empresaId: this.empresa.id }).then(() => {
            this.$swal({
              title: 'Sucesso!',
              text: `As instruções para recuperação de senha foram enviadas para o e-mail ${this.userEmail}`,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.userEmail = ''
          }).catch(error => {
            this.$swal({
              title: 'Error!',
              text: error.data[0].erro,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }).finally(() => {
            this.loadingOverlay = false
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
