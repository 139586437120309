import axios from '@axios'
import store from '@/store'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    // linguagem
    setLanguage(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('usuario-configuracao/idioma', data)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    // tela
    getConfigTela() {
      return new Promise((resolve, reject) => {
        axios.get('usuario-configuracao/tela')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    setConfigTela(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('usuario-configuracao/tela', data)
          .then(response => {
            store.commit('appConfig/UPDATE_SKIN', data.modoTela)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    // binance api

    getInfoBinanceApi() {
      return new Promise((resolve, reject) => {
        axios.get('usuario-info/api/info')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getConfigBinanceApi() {
      return new Promise((resolve, reject) => {
        axios.get('usuario-configuracao/api')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    setConfigBinanceApi(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('usuario-configuracao/api', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Futures - position mode
    getModoPosicaoHedge() {
      return new Promise((resolve, reject) => {
        axios.get('fut/usuario-info/modo-posicao-hedge')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    setModoPosicaoHedge(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('fut/usuario-info/modo-posicao-hedge', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // notificacoes
    getConfigNotificacao() {
      return new Promise((resolve, reject) => {
        axios.get('usuario-configuracao/notificacao')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    setConfigNotificacao(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('usuario-configuracao/notificacao', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    telegramNotificacaoEnvioTeste() {
      return new Promise((resolve, reject) => {
        axios.get('healthcheck/telegram-notificacao')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // senhas
    alterarSenha(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('usuario-configuracao/alterar-senha', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    recuperarSenha(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('usuario-configuracao/recuperar-senha', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    trocarSenha(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('usuario-configuracao/trocar-senha', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
